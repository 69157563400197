:root {
    --brand-color: #edc139;
    --text-color: rgb(63, 63, 63);
}

html {
    background-color: #f8f8f8;
    max-width: 35rem;
    margin: 0 auto;
}

body {
    color: var(--text-color);
    padding: 0 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Condensed', sans-serif;
}

h1 {
    text-align: center;
    margin: 3rem 0;
}

h2 {
    text-align: center;
    margin-top: 3rem;
}

p,
label {
    font-family: 'Open Sans', sans-serif;
}

.intro-sentence {
    text-align: center;
    margin-bottom: 2rem;
}

/* Text Area & Submit Button */

label {
    display: block;
    font-weight: bold;
    margin-bottom: 1rem;
}

.submit-button {
    display: block;
    background-color: var(--brand-color);
	border: none;
    border-radius: 10px;
	padding: .5rem 1rem;
    margin: 1.5rem 0;
	text-transform: uppercase;
    color: inherit;
    font-weight: bold;
    cursor: pointer;
}

.submit-button:disabled {
    color: rgba(63, 63, 63, 0.3);
}

.submit-button:hover {
    box-shadow: 0 0 10px var(--brand-color);
}

textarea {
    width: calc(100% - 2rem);
    height: 10vh;
    padding: 1rem;
    font-family: 'Open Sans', sans-serif;
    color: var(--text-color);
    background-color: rgb(223, 222, 222);
    border-radius: 10px;
    border: none;
}

textarea:focus {
    outline: none !important;
    border: 1px solid var(--brand-color);
    box-shadow: 0 0 10px var(--brand-color);
}

/* Emoji buttons */

.emoji-buttons button {
    font-size: 2rem;
    background: none;
	border: none;
    cursor: pointer;
    margin: .5rem;
}

.emoji-buttons {
    text-align: center;
}
